import CSS from 'CSS/main.css'
import React, { Suspense } from 'react'

import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

//import BasicCard from '../../cards/basic-card'
const BasicCard = React.lazy(() => import('../../cards/basic-card'))


function Dashboard(props) {
  const { } = props;

  return (
    <>
      <div className="parallax bg-dark">
        <div className="parallax-group group-1 pieces">
          <div className="parallax-layer back-layer-1"></div>
          <Suspense>
            <div className="parallax-layer base-layer-1">
              <Row className="g-0 intro-card lato-bold">
                <BasicCard
                  headerLineOne={"YOU'VE TRIED THE REST, NOW TRY THE BEST."}
                />
              </Row>

              <Row className="g-0" style={{ marginLeft: '24vw', marginTop: '20vh' }} >
                <Card as={Col} sm={10} className="rounded-0" style={{ minHeight: '500px'}}>
                  <Card.Body></Card.Body>
                </Card>
              </Row>
            </div>
          </Suspense>
        </div>
      </div>

      <div className="parallax">
        <div className="parallax-group group-2 pieces">
          <div className="parallax-layer back-layer-2"></div>
        </div>
      </div>
    </>
  )
}

export default Dashboard;

/*
<Container fluid className="d-flex flex-column h-100 overflow-hidden p-0">
  <div className="parallax"></div>
  <Row className="g-0 w-100 mx-5" style={{ backgroundColor: 'rgba(255,255,255,.5)' }}>
    <BasicCard
      headerLineOne={"YOU'VE TRIED THE REST, NOW TRY THE BEST."}
    />
  </Row>

  <Row className="g-0 w-100">

  </Row>
</Container>

<div className="overlay">
  <div></div>
</div>
<section className="parallax-group intro">
  <div className="parallax-layer background">
    <Container fluid className="overflow-hidden p-0 background-container">
      <div className="background-images"></div>
    </Container>
    <div className="content">
    </div>
  </div>
</section>
*/
