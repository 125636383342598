import React from 'react'

import Row from 'react-bootstrap/Row'

import AppHeader from './components/header/app-header'
import Dashboard from './components/views/dashboard/dashboard'


const App = () => {

  return (
    <div className="d-flex flex-column h-100">
      <AppHeader />
      <Row className="flex-grow-1 g-0 overflow-hidden">
        <Dashboard />
      </Row>
    </div>
  )
}

export default App;
