import CSS from 'CSS/app-header.css'
import React from 'react'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Row from 'react-bootstrap/Row'


function AppHeader(props) {
  const {} = props;

  const links = ['Home', 'About', 'Schedule', 'Testimonials'];

  const navLinks = links.map((link, i) => {
    return (
      <Nav.Item as="h6" className="px-3 mb-0" key={i}>
        <Nav.Link className="text-uppercase text-dark" href="">{link}</Nav.Link>
      </Nav.Item>
    )
  });

  const navButton =
    <Nav.Item as={Button}
      className="p-0 px-3 rounded-0 align-items-center position-relative btn-effect border-0"
    >
      <span className="pe-2 oswald red fw-bold">FREE CLASS</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
      </svg>
    </Nav.Item>


  return (
    <Navbar className="p-5">
      <Navbar.Brand className="px-5 ">
        <Image as={Col} src="http://www.ahnstaekwondo.com/wp-content/uploads/2018/10/ahn_favicon_114.png" width={85}  />
        <span className="playfair fw-bold red" style={{ fontSize: '2.25em' }}>Ahn's Tae Kwon Do</span>
      </Navbar.Brand>
      <Nav as={Col} className="justify-content-center">
        {navLinks}
      </Nav>
        {navButton}
    </Navbar>

  )
}

export default AppHeader
